import { useLocation } from "@remix-run/react"
import { getState, setState } from "atom.io"
import { findState } from "atom.io/ephemeral"
import { useCallback, useEffect, useRef, useState } from "react"
import { v4 as uuid } from "uuid"

import {
	messageAtoms,
	messageIndexAtoms,
} from "@/app/components/layout/Conversation"
import { useToast } from "@/app/components/toaster"

import type { Toast } from "../server/toast.server"
import type { LogChunk, MessageChunk } from "../stream-utils"
import { decodeChunk } from "../stream-utils"

export const logEmojis: Record<LogChunk[`type`], string> = {
	error: `❌`,
	info: `ℹ️`,
	warn: `⚠️`,
}

export const handleMessage = (event: MessageEvent<string>) => {
	const {
		content: newTextChunk,
		messageId: newMessageId,
		conversationId,
		endOfMessage,
	} = decodeChunk<MessageChunk>(event.data) ?? {}
	if (!newMessageId || !conversationId) {
		return
	}

	const messageState = findState(messageAtoms, newMessageId)

	setState(messageState, (prev) => ({
		...prev,
		id: newMessageId,
		content: prev.content + (newTextChunk ?? ``),
		isStreaming: !endOfMessage,
	}))
	const messageIndex = findState(messageIndexAtoms, conversationId)
	const knownMessageIds = getState(messageIndex)
	if (!knownMessageIds.includes(newMessageId)) {
		setState(messageIndex, (prev) => [...prev, newMessageId])
	}

	if (endOfMessage) {
		setState(messageState, (prev) => ({
			...prev,
			isStreaming: false,
		}))
	}
}

export const handleLog =
	(setToast: (toast: Toast) => void) => (event: MessageEvent<string>) => {
		const {
			title,
			data = {},
			type = `info`,
		} = decodeChunk<LogChunk>(event.data) ?? {}
		if (!title) return

		console.groupCollapsed(`${logEmojis[type]} ${title}`)
		console.log(data)
		console.groupEnd()

		if (type === `error`) {
			setToast({
				description: title,
				id: uuid(),
				type: `error`,
				title: `Error streaming message`,
			})
		}
	}

const useChatStream = () => {
	const location =
		// this is the only simple way to get use-chat-stream.test.ts passing because it doesn't have remix context
		import.meta.env?.MODE === `test` ? { pathname: `/` } : useLocation() // NOSONAR
	const prevLocationPathname = useRef<string | null>(null)
	const disconnectFromStream = useRef<() => void>(() => {
		console.warn(`disconnectFromStream was called before being set`)
	})

	const [toast, setToast] = useState<Toast | null>(null)
	useToast(toast)

	const handleLogWithToast = useCallback(handleLog(setToast), [])

	useEffect(() => {
		const cameFromHome = prevLocationPathname.current === `/`
		const cameFromChat = prevLocationPathname.current?.startsWith(`/chat`)
		const navigatedToHome = location.pathname === `/`
		const navigatedToChat = location.pathname.startsWith(`/chat`)
		const wasStreaming = cameFromHome || cameFromChat
		const needsToStream = navigatedToHome || navigatedToChat

		prevLocationPathname.current = location.pathname

		if (!wasStreaming && !needsToStream) {
			return // nothing to do
		}
		if (wasStreaming && needsToStream) {
			return disconnectFromStream.current
		}
		if (wasStreaming && !needsToStream) {
			disconnectFromStream.current()
			return // nothing to clean up
		}
		if (!wasStreaming && needsToStream) {
			const eventSource = new EventSource(`/sse`)
			disconnectFromStream.current = () => {
				eventSource?.removeEventListener(`message`, handleMessage)
				eventSource?.removeEventListener(`log`, handleLogWithToast)
				removeEventListener(`beforeunload`, disconnectFromStream.current)
				eventSource.close()
			}
			eventSource.addEventListener(`message`, handleMessage)
			eventSource.addEventListener(`log`, handleLogWithToast)
			addEventListener(`beforeunload`, disconnectFromStream.current)
		}
		return disconnectFromStream.current
	}, [handleLogWithToast, location.pathname])
}

export default useChatStream
